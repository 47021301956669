import React, { useEffect, useRef, useState, useContext } from "react";
import Form from "react-bootstrap/Form";
import "./contact.css";

import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import Success_modal from "../common-section/Modals/Success_modal/Success_modal";
import { Context } from "../../utils/context";
import { RegxExpression } from "../../utils/common";
import { useForm, Controller } from "react-hook-form";
import classNames from "classnames";
import { ContactUsForm } from "../../utils/apis/form/form";
import { AppSetup } from "../../utils/apis/common/Common";
const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when component mounts
  }, []);

  const {
    IMG_URL,
    signin,
    triggerCartUpdate,
    setopen_delivery,
    open_delivery,
    setquick_delivery,
    quick_delivery,
    appSetup,
    setAppSetup,
    setSellerPincode,
    sellerPincode,
    deliveryPincode,
    outOfStock,
  } = useContext(Context);

  const [mapSrc, setMapSrc] = useState(
    "https://maps.google.com/maps?width=600&amp;height=400&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
  );

  const [setups, setSetup] = useState();
  const getSetup = async () => {
    const res = await AppSetup();
    if (res?.success) {
      setSetup(res?.data);
      setMapSrc(
        `https://maps.google.com/maps?width=600&height=400&hl=en&q=${res?.data?.address}&t=&z=14&ie=UTF8&iwloc=B&output=embed`
      );
    }
  };

  useEffect(() => {
    getSetup();
  }, []);
  const [modalShow, setModalShow] = useState(false);

  const [phoneValue, setPhoneValue] = useState("");

  function handleClick(el) {
    el.classList.toggle("float-label-top");
  }

  const [isFlipped, setIsFlipped] = useState(false);

  const handleImageClick = () => {
    setIsFlipped(!isFlipped); // Toggle flip status
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("subject", data?.subject);
    formData.append("message", data?.message);
    formData.append("agree_status", data?.agree_status);
    formData.append("name", data?.name);
    formData.append("email", data?.email);
    formData.append("contact_no", data?.contact_no);

    const res = await ContactUsForm(formData);
    if (res?.success) {
      reset();
      setModalShow(true);
    } else {
    }
  };

  return (
    <>
      <section className="Contact_section">
        {/* <div className='overlay-bgg'></div> */}
        <div className="container ">
          <div className="row contmainpos">
            <div className="col-md-8">
              <div className="form-sec">
                <h1 className="conttitle">Contact Us</h1>

                <div
                  className={`flip-card ${
                    isFlipped ? "flipped" : "" || isFlipped ? "" : ""
                  } `}
                >
                  <div className="flip-card-inner">
                    <div className="flip-card-front">
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Group className="form-group">
                          <Form.Label>Enter Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="name"
                            placeholder="Enter Name"
                            {...register("name", {
                              required: "name required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.name,
                              "is-valid": getValues("name"),
                            })}
                            onKeyDown={(event) => {
                              if (!RegxExpression.name.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                          />
                        </Form.Group>

                        <Form.Group className="form-group">
                          <Form.Label>Enter Email</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Enter Email"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value: RegxExpression.email,
                                message: "Invalid email address",
                              },
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.email,
                              "is-valid": getValues("email"),
                            })}
                          />
                        </Form.Group>

                        <Form.Group className="form-group">
                          <Controller
                            name="contact_no"
                            control={control}
                            rules={{
                              required: "contact No is required",
                              minLength: {
                                value: 10,

                                message:
                                  "Number should be at least 10 characters",
                              },
                            }}
                            render={({ field }) => (
                              <Form.Control
                                {...field}
                                className={classNames("", {
                                  "is-invalid": errors?.contact_no,
                                  "is-valid": getValues("contact_no"),
                                })}
                                onChange={(value) => field.onChange(value)}
                                type="text"
                                placeholder="Enter Mobile Number"
                                onKeyDown={(event) => {
                                  if (!RegxExpression.phone.test(event.key)) {
                                    if (event.key !== "Backspace") {
                                      event.preventDefault();
                                    }
                                  }
                                }}
                                maxLength={10}
                              />
                            )}
                          />
                          {/* {errors?.contact_no && (
                            <sup className="text-danger">
                              {errors?.contact_no?.message}
                            </sup>
                          )} */}
                          {/* <PhoneInput
                          className="mb-3"
                          country={"in"}
                          placeholder="Enter mobile number"
                          value={phoneValue}
                          onChange={setPhoneValue}
                          inputProps={{
                            name: "phone",
                            required: true,
                            autoFocus: true,
                          }}
                        /> */}
                        </Form.Group>

                        <Form.Group className="form-group">
                          <Form.Label>Type Message</Form.Label>
                          <Form.Control
                            type="text"
                            name="message"
                            placeholder="Enter message"
                            {...register("message", {
                              required: "message required",
                            })}
                            className={classNames("", {
                              "is-invalid": errors?.message,
                              "is-valid": getValues("message"),
                            })}
                          />
                        </Form.Group>

                        <button
                          className="submit-btn"
                          type="submit"
                          // onClick={() => setModalShow(true)}
                        >
                          Submit
                        </button>
                      </Form>
                      <img
                        className="small-map-img"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/contact/small-map.png"
                        }
                        onClick={handleImageClick} // Click handler to toggle flip
                      />
                    </div>

                    <div className="flip-card-back">
                      <div className="map-section">
                        <div className="mapouter">
                          <div className="gmap_canvas">
                            <iframe
                              className="gmap_iframe"
                              frameborder="0"
                              scrolling="no"
                              marginheight="0"
                              marginwidth="0"
                              src={mapSrc}
                            ></iframe>
                            <a
                              href="https://pdflist.com/"
                              alt="pdflist.com"
                            ></a>
                          </div>
                        </div>

                        <img
                          onClick={handleImageClick}
                          className="close-map-icon"
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/closemap.png"
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="col-md-4">
              
            </div> */}

            <div className="img-sec">
              <img
                className="banner-img"
                src={
                  process.env.PUBLIC_URL + "/assets/images/contact/banner.png"
                }
              />
            </div>
          </div>
        </div>
      </section>

      <Success_modal
        maintext={"Message Sent Successfully"}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Contact;
