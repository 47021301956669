import React from "react";
import { Form, Modal } from "react-bootstrap";
import "./RescheduleModal.css";
import { recheduleDate } from "../../../utils/apis/myAccount/myAccount";
import { useForm } from "react-hook-form";

const RescheduleModal = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("start_date", data?.start_date);

    const res = await recheduleDate(props?.show, data);
    if (res?.success) {
      props.onHide();
      props.getAllBookingData();
      props.getUserSubscription();
      reset(); // Reset form after submission
    } else {
      // Handle error case here, e.g., show error message
    }
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="reschedule-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="rescchedule-title">Reschedule</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Reschedule Date</Form.Label>
              <Form.Control
                type="date"
                min={new Date().toISOString().split("T")[0]} // Set min date as today
                {...register("start_date", {
                  required: "Reschedule date is required",
                  validate: (value) => {
                    const selectedDate = new Date(value);
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    return selectedDate >= today || "Please Select Future Date";
                  },
                })}
              />
              {errors.start_date && (
                <p className="error-text">{errors.start_date.message}</p>
              )}
            </Form.Group>

            <div className="text-center mt-4 mb-4">
              <button className="save-btnnnn me-2" type="submit">
                Save
              </button>
              <button
                className="close-buttonnn"
                type="button"
                onClick={props.onHide}
              >
                Close
              </button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RescheduleModal;
