import { getData, postData } from "../../api";

// Blog
export const postBooking = async (data) => {
  try {
    const res = await postData(`/booking`, data);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const postCalculation = async (data) => {
  try {
    const res = await postData(`/booking/calculation`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const LatestNewsDetails = async (id) => {
  try {
    return await getData(`/latest-news/${id}`);
  } catch (error) {
    console.log(error);
  }
};

// Faq

export const faqCategory = async (id) => {
  try {
    return await getData(`/faq`);
  } catch (error) {
    console.log(error);
  }
};

export const FaqCategoryData = async (id) => {
  try {
    return await getData(`/faq`);
  } catch (error) {
    console.log(error);
  }
};

export const FaqData = async (id) => {
  try {
    return await getData(`/faq/${id}`);
  } catch (error) {
    console.log(error);
  }
};

export const FaqFAqData = async (id) => {
  try {
    return await getData(`/faq/all`);
  } catch (error) {
    console.log(error);
  }
};
