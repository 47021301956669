import { useState, useEffect, useContext } from "react";
import "../../bookings-form.css";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "react-phone-input-2/lib/style.css";
import Accordion from "react-bootstrap/Accordion";
import Success_modal from "../../../common-section/Modals/Success_modal/Success_modal";
import { useForm, Controller } from "react-hook-form";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import { RegxExpression } from "../../../../utils/common";

import classNames from "classnames";
import useRazorpay from "react-razorpay";
import { getAdditionalSupport } from "../../../../utils/apis/master/Master";
import {
  postBooking,
  postCalculation,
} from "../../../../utils/apis/booking/booking";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../../utils/context";
import { UserGet } from "../../../../utils/apis/myAccount/myAccount";
import {
  perticularUserBookings,
  userSubscriptionDetails,
} from "../../../../utils/apis/MediaSpace/MediaSpace";
const Daily_form = ({ data }) => {
  console.log(data, "dataddddddddddddddddddddddddddd");

  const { IMG_URL, appSetup, userdata, setUserData } = useContext(Context);

  const [userSubscription, setSubscription] = useState();

  const [userBooking, setUserBookings] = useState();

  console.log(data?.open_24_hours, "datatatatatatat");
  console.log(userSubscription, "userSubscription");

  const getUserSubscription = async () => {
    const res = await userSubscriptionDetails();
    if (res?.success) {
      setSubscription(res.data);
    }
  };

  const getUserBooking = async () => {
    const res = await perticularUserBookings();
    if (res?.success) {
      setUserBookings(res.data);
    }
  };

  const getUser = async () => {
    const res = await UserGet();
    if (res?.success) {
      setUserData(res.data);
    }
  };

  useEffect(() => {
    getUser();
    getUserBooking();
    getUserSubscription();
  }, []);

  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [value, onChange] = useState("10:00");

  const [phoneValue, setPhoneValue] = useState("");
  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      calculation_status: false,
    },
  });

  const watchStartDate = watch("start_date");
  const watchEndDate = data?.open_24_hours ? watchStartDate : watch("end_date");
  const watchStartTime = watch("start_time");
  const watchEndTime = watch("end_time");

  const startTime = new Date(`1970-01-01T${watchStartTime}:00`);
  const formattedStartTime = startTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  const endTime = new Date(`1970-01-01T${watchEndTime}:00`);
  const formattedEndTime = endTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  const [studioId, seStudioId] = useState();

  const [useDays, setUseDays] = useState();

  console.log(useDays, "useDaysuseDays");

  const [useRoundedHours, setRoundedHours] = useState();
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (watchStartDate) {
      setStartDate(new Date(watchStartDate));
    }
  }, [watchStartDate]);
  const [totalCharge, setTotalCharge] = useState(0);
  const [chargesError, setChargesError] = useState(false);

  useEffect(() => {
    seStudioId(data?.id);
    if (watchStartDate && watchEndDate && watchStartTime && watchEndTime) {
      const startDate = new Date(watchStartDate);
      const endDate = new Date(watchEndDate);

      console.log(startDate, "startDate");
      console.log(endDate, "endDate");

      const startTime = new Date(`1970-01-01T${watchStartTime}:00`);
      const formattedStartTime = startTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });
      const endTime = new Date(`1970-01-01T${watchEndTime}:00`);
      const formattedEndTime = endTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
      });

      const days = Math.ceil((endDate - startDate) / (1000 * 60 * 60 * 24) + 1);
      setUseDays(days);

      setValue("days", days);
      function parseTimeToMilliseconds(timeStr) {
        const [hours, minutes] = timeStr.split(":").map(Number);
        return (hours * 60 + minutes) * 60 * 1000; // Convert to milliseconds
      }

      const formattedStartTimenow = formattedStartTime;
      const formattedEndTimenow = formattedEndTime;

      const startTimeMs = parseTimeToMilliseconds(formattedStartTimenow);
      const endTimeMs = parseTimeToMilliseconds(formattedEndTimenow);

      const hours = Math.max((endTimeMs - startTimeMs) / (1000 * 60 * 60), 0);
      const roundedHours = Math.ceil(hours);
      setRoundedHours(roundedHours);

      // Calculate charges
      // const dayCharges = days * data?.full_day_charges;
      // const hourCharges = TimeDifference * data?.hourly_basis_charges;

      // setTotalCharge(dayCharges + hourCharges);
    }
  }, [watchStartDate, watchEndDate, watchStartTime, watchEndTime]);

  const selectedAddOns = watch("add_on_id");

  const [checkedAddOns, setCheckedAddOns] = useState([]);
  const [totalAddOnCharges, setTotalAddOnCharges] = useState(0);

  const handleCheckboxChange = (id, checked, rate) => {
    const currentAddOns = selectedAddOns || [];

    if (checked) {
      const newTotalCharge = totalCharge + Number(rate);
      setValue("add_on_id", [...currentAddOns, id]);
      setTotalCharge(newTotalCharge);
      setTotalAddOnCharges((prevTotal) => prevTotal + Number(rate));
    } else {
      const newTotalCharge = totalCharge - Number(rate);
      setValue(
        "add_on_id",
        currentAddOns.filter((itemId) => itemId !== id)
      );
      setTotalCharge(newTotalCharge);
      setTotalAddOnCharges((prevTotal) => prevTotal - Number(rate));
    }
  };

  const selectedsupport = watch("additional_support_id");

  const handleCheckboxChangeSupport = (id, checked) => {
    const currentAddOns = selectedsupport || [];
    if (checked) {
      setValue("additional_support_id", [...currentAddOns, id]);
    } else {
      setValue(
        "additional_support_id",
        currentAddOns.filter((itemId) => itemId !== id)
      );
    }
  };

  const [dataSupport, setDataSupport] = useState();
  const getAllData = async () => {
    const res = await getAdditionalSupport();
    if (res?.success) {
      setDataSupport(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const [Razorpay, openRazorpay] = useRazorpay();
  const [razorid, setRazorId] = useState();
  const onSubmit = (datafinal) => {
    if (datafinal?.calculation_status) {
      const selectedStartDate = new Date(datafinal.start_date)
        .toISOString()
        .split("T")[0];

      // If open_24_hours is true, use the start date as the end date, otherwise use the actual end date.
      const selectedEndDate = data?.open_24_hours
        ? selectedStartDate
        : datafinal.end_date
        ? new Date(datafinal.end_date).toISOString().split("T")[0]
        : selectedStartDate;

      const { start_time, end_time } = datafinal;

      // Check for booking conflicts
      const isBooked = data?.book_studios?.some((booking) => {
        const bookingStartDate = new Date(booking.start_date)
          .toISOString()
          .split("T")[0];

        const bookingEndDate = new Date(booking.end_date)
          .toISOString()
          .split("T")[0];
        const bookingDate = new Date(booking.date).toISOString().split("T")[0];

        // Check if selected date range overlaps with booking date range
        const isDateOverlap =
          (selectedStartDate >= bookingStartDate &&
            selectedStartDate <= bookingEndDate) ||
          (selectedEndDate >= bookingStartDate &&
            selectedEndDate <= bookingEndDate) ||
          (bookingStartDate >= selectedStartDate &&
            bookingStartDate <= selectedEndDate) ||
          (bookingEndDate >= selectedStartDate &&
            bookingEndDate <= selectedEndDate) ||
          selectedStartDate === bookingDate ||
          selectedEndDate === bookingDate;

        const isTimeOverlap =
          (start_time >= booking.start_time && start_time < booking.end_time) ||
          (end_time > booking.start_time && end_time <= booking.end_time) ||
          (start_time <= booking.start_time && end_time >= booking.end_time);

        return isDateOverlap && isTimeOverlap;
      });

      if (isBooked || chargesError) {
        if (chargesError !== 0) {
          // Handle chargesError
        }
        if (isBooked) {
          setError("end_time", {
            type: "manual",
            message:
              "This time slot is already booked within the selected date range.",
          });
        }
      } else {
        clearErrors("end_time");
        handleRazorpay(datafinal);
      }
    } else {
      setError("calculation_status", {
        type: "manual",
        message: "Please press Calculate",
      });
    }
  };

  const [loading, setLoading] = useState(false);

  const handleRazorpay = async (data) => {
    let amountToPay = Math.round(details?.total);

    // Prepare Razorpay options
    const options = {
      key: "rzp_test_I05KCFb1v5JJRc",
      amount: Math.round(amountToPay * 100), // Amount in paise (multiplied by 100)
      currency: "INR",
      name: "FlickNShoot",
      description: "Test Transaction",
      image:
        "http://localhost:3000/static/media/hippicart-logo.427718645d3015dbf003.png",
      handler: async (res) => {
        await setRazorId(res.razorpay_payment_id);
        finalFunction(data, res.razorpay_payment_id);
        console.log(res, "Payment response");
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#052c65",
      },
    };

    const rzp = new window.Razorpay(options);
    rzp.open();
  };

  // const [error, setError] = useState(0);
  const finalFunction = async (data, payment_id) => {
    setLoading(true); // Start the loader

    const incrementDate = (dateStr) => {
      const date = new Date(dateStr);
      date.setDate(date.getDate() + 1);
      return date.toISOString().split("T")[0]; // format as 'YYYY-MM-DD' or adapt as needed
    };

    const datafinal = {
      studio_id: studioId,
      type: "Daily",
      start_time: data?.start_time,
      capacity: data?.capacity,
      end_time: data?.end_time,
      start_date: incrementDate(data?.start_date),
      end_date: incrementDate(data?.start_date),
      name: data?.name,
      amount: Math.round(details?.total),
      payment_id: payment_id,
      contact_no: data?.contact_no,
      days: data?.days,
      add_on_id: data?.add_on_id,
      additional_support_id: data?.additional_support_id,
      support_description: data?.support_description,
      platform_charges: details?.p_charge_p,
      afteroffer: details?.afteroffer,
      gst_charge: details?.gstchargestoshow,
      add_on_price: details?.add_one_price,
      free_hours: details?.free_h_p_discount,
      base_price_discount_rupees: details?.basepricediscountrupees,
      base_price_discount: details?.base_price_discount,
      base_price: details?.base_price,
    };
    if (userdata?.subscription_status == 1) {
      datafinal.after_subscription_date = "Subscribe Payment";
    }

    if (data?.email) {
      datafinal.email = data?.email;
    }

    if (data?.description) {
      datafinal.description = data?.description;
    }

    const res = await postBooking(datafinal);

    if (res?.success) {
      reset();
      setModalShow(true);
      setTimeout(() => {
        setModalShow(false);
        navigate("/");
      }, 3000);
    } else {
      setError("message", res?.message);
    }

    setLoading(false); // Stop the loader
  };

  const errorStyle = {
    color: "red",
    fontSize: "14px",
    margin: "10px 0",
  };

  const [details, setDetails] = useState({
    days: 0,
    base_price: 0,
    charges: {
      full_h_p: 0,
      full_h_c: 0,
      half_h_p: 0,
      half_h_c: 0,
      min_h_p: 0,
      min_h_c: 0,
    },
    platform_charge: 0,
    gst: 0,
    start_time: "00:00:00",
    end_time: "00:00:00",
    hours: 0,
    total: 0,
    add_one_price: 0,
    free_h_p_discount: 0,
  });

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isCalculationDone, setIsCalculationDone] = useState(false);
  const [calculationError, setCalculationError] = useState(false);

  console.log(isButtonDisabled, "isButtonDisabled");

  const onSubmitCalculation = async (add_one_price = 0) => {
    setIsButtonDisabled(true);

    const f_data = {
      days: data?.open_24_hours == 1 ? 1 : getValues("days"),
      hours: getValues("hours"),
      studio_id: data?.id,
      r_s_time: formattedStartTime,
      r_e_time: watchEndTime,
      add_one_price: totalCharge,
      capacity: getValues("capacity"),
    };

    try {
      const res = await postCalculation(f_data);

      if (res?.success) {
        setCalculationError(false);
        setDetails(res.data);
        setValue("end_time", res?.data?.end_time);
        setValue("calculation_status", res?.success);
        setIsCalculationDone(true);
      } else {
        setCalculationError(res?.message);
      }
    } catch (error) {
      // Handle error
    } finally {
      setIsButtonDisabled(false);
    }
  };

  console.log(getValues(), "getvalue");

  return (
    <>
      {loading && <div className="loader">Loading...</div>}
      <div className="booking-from-sec">
        <div className="row">
          <div className="col-lg-11 mx-auto">
            <div className="form-bg">
              <div className="row">
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <Controller
                      name="start_date"
                      control={control}
                      // defaultValue={new Date()}
                      rules={{ required: "Start Date Is Required" }}
                      render={({ field }) => (
                        <DatePicker
                          {...field} // This will spread the field's props, including value and onChange
                          selected={field.value}
                          onChange={(date) => {
                            field.onChange(date);
                            setValue("start_date", date);
                            setValue("end_date", "");
                            setIsCalculationDone(false);
                          }}
                          autoComplete="off"
                          className="form-control"
                          placeholderText="MM/DD/YYYY"
                          minDate={new Date()}
                        />
                      )}
                    />
                    {errors.start_date && (
                      <div className="text-danger">
                        {errors.start_date.message}
                      </div>
                    )}
                  </div>
                </div>
                {data?.open_24_hours == 1 ? (
                  ""
                ) : (
                  <div className="col-lg-3 col-md-6">
                    <div className="form-group">
                      <Controller
                        name="end_date"
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            selected={field.value}
                            {...register("end_date", {
                              required: "End Date Is Required",
                            })}
                            onChange={(date) => {
                              // Ensure startDate is a valid Date object
                              if (!startDate || !(startDate instanceof Date)) {
                                console.error("Invalid startDate:", startDate);
                                return;
                              }

                              // Normalize startDate and endDate to remove the time part
                              const startDateOnly = new Date(
                                startDate.getFullYear(),
                                startDate.getMonth(),
                                startDate.getDate()
                              );
                              const endDateOnly = new Date(
                                date.getFullYear(),
                                date.getMonth(),
                                date.getDate()
                              );

                              console.log("Start Date:", startDateOnly);
                              console.log("End Date:", endDateOnly);

                              // Calculate the difference in days
                              const days = Math.max(
                                Math.ceil(
                                  (endDateOnly - startDateOnly) /
                                    (1000 * 60 * 60 * 24) +
                                    1 // Add 1 to include both start and end dates
                                ),
                                1
                              );

                              console.log("Days Difference:", days);

                              // Update the days and end_date fields

                              setValue("end_date", date);
                              setValue("days", days);
                              setValue("calculation_status", false);
                              field.onChange(date);
                              setIsCalculationDone(false);
                            }}
                            autoComplete="off"
                            className="form-control"
                            placeholderText="MM/DD/YYYY"
                            minDate={startDate}
                          />
                        )}
                      />
                      {errors.end_date && (
                        <div className="text-danger">
                          {errors.end_date.message}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <Controller
                      name="start_time"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          {...register("start_time", {
                            required: "Start Time Is Required",
                          })}
                          className="form-control"
                          onChange={(time) => {
                            field.onChange(time);
                            setValue("start_time", time);
                            setValue("calculation_status", false);
                            setChargesError("");
                            setIsCalculationDone(false);
                          }}
                          value={field.value}
                          disableClock={true}
                        />
                      )}
                    />
                    {errors.start_time && (
                      <div className="text-danger">
                        {errors.start_time.message}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <Controller
                      name="hours"
                      control={control}
                      rules={{
                        required: "Hours are required",
                        min: { value: 1, message: "Give Positive Value" },
                        // max: { value: 24, message: "Hours cannot exceed 24" },
                      }}
                      render={({ field }) => (
                        <Form.Control
                          type="number"
                          name="hours"
                          placeholder="Enter Hours"
                          {...register("hours", {
                            required: "Hours Is Required",
                          })}
                          onChange={(e) => {
                            const value = e.target.value;
                            const parsedValue = parseFloat(value);
                            if (parsedValue >= 0 && parsedValue <= 24) {
                              setValue("hours", parsedValue);
                              setValue("calculation_status", false);
                              field.onChange(parsedValue);
                            }
                            setIsCalculationDone(false);
                          }}
                          className={classNames("", {
                            "is-invalid": errors?.hours,
                            "is-valid": !errors?.hours && getValues("hours"),
                          })}
                        />
                      )}
                    />
                    {errors?.hours && (
                      <span className="invalid-feedback">
                        {errors.hours.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <Controller
                      name="capacity"
                      control={control}
                      rules={{
                        required: "Hours are required",
                        min: { value: 1, message: "Give Positive Value" },
                        // max: { value: 24, message: "Hours cannot exceed 24" },
                      }}
                      render={({ field }) => (
                        <Form.Control
                          type="number"
                          name="capacity"
                          placeholder="Enter Crew Capacity"
                          {...register("capacity", {
                            required: "capacity Is Required",
                          })}
                          onChange={(e) => {
                            const value = e.target.value;
                            const parsedValue = parseFloat(value);

                            setValue("capacity", parsedValue);
                            setValue("calculation_status", false);
                            setIsCalculationDone(false);
                          }}
                          className={classNames("", {
                            "is-invalid": errors?.capacity,
                            "is-valid":
                              !errors?.capacity && getValues("capacity"),
                          })}
                        />
                      )}
                    />
                    {errors?.capacity && (
                      <span className="invalid-feedback">
                        {errors.capacity.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-lg-3 col-md-6">
                  <div className="form-group">
                    <Controller
                      name="end_time"
                      control={control}
                      render={({ field }) => (
                        <TimePicker
                          disabled
                          // {...register("end_time", {
                          //   required: "End Time Is Required",
                          // })}
                          className="form-control"
                          onChange={(time) => {
                            field.onChange(time);
                            setValue("calculation_status", false);
                            setValue("end_time", time);
                            setChargesError("");
                          }}
                          value={field.value}
                          disableClock={true}
                        />
                      )}
                    />
                    {errors.end_time && (
                      <div className="text-danger">
                        {errors.end_time.message}
                      </div>
                    )}
                  </div>

                  {chargesError && <div style={errorStyle}>{chargesError}</div>}
                </div>{" "}
                <div className="col-lg-9 col-md-6 text-end icresvaliderrr">
                  <p>{calculationError}</p>
                </div>
              </div>{" "}
            </div>{" "}
          </div>

          <div className="col-lg-11 mx-auto mt-5">
            <div className="form-bg">
              <div className="row">
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Whole day Charges:</Form.Label>
                    <p className="charges-text-holder">
                      {data?.full_day_charges} ( {data?.full_day_hours} Hours)
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Half day Charges:</Form.Label>
                    <p className="charges-text-holder">
                      {data?.half_day_charges}( {data?.half_day_hours} Hours)
                    </p>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Hourly Charges:</Form.Label>
                    <p className="charges-text-holder">
                      {data?.hourly_basis_charges}
                    </p>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Start Time</Form.Label>
                    <p className="charges-text-holder">
                      {data?.full_d_start_time}
                    </p>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>End Time</Form.Label>
                    <p className="charges-text-holder">
                      {data?.full_d_end_time}
                    </p>
                  </div>
                </div>{" "}
                <div className="col-lg-4 col-md-6">
                  <div className="form-group">
                    <Form.Label>Studio crew Capacity</Form.Label>
                    <p className="charges-text-holder">
                      {data?.studio_crew_capacity}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-9 col-md-12 mx-auto ">
            <Form>
              <h6 className="estimatedh6">
                Estimated Booking Cost: ₹ {details?.total} /-
              </h6>

              <div className="form-bg">
                <div className="form-group">
                  <Form.Label>Enter Name*</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Enter Name"
                    {...register("name", {
                      required: "Name Is Required",
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.name,
                      "is-valid": getValues("name"),
                    })}
                    onKeyDown={(event) => {
                      if (!RegxExpression.name.test(event.key)) {
                        event.preventDefault();
                      }
                    }}
                  />
                  {errors?.name && (
                    <sup className="text-danger">{errors?.name?.message}</sup>
                  )}
                </div>

                <div className="form-group">
                  <Form.Label>Enter Email*</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Enter Email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value: RegxExpression.email,
                        message: "Invalid email address",
                      },
                    })}
                    className={classNames("", {
                      "is-invalid": errors?.email,
                      "is-valid": getValues("email"),
                    })}
                  />
                  {errors?.email && (
                    <sup className="text-danger">{errors?.email?.message}</sup>
                  )}
                </div>

                <div className="form-group">
                  <Form.Label>Enter Contact Number*</Form.Label>
                  <Controller
                    name="contact_no"
                    control={control}
                    rules={{
                      required: "Contact Number is required",
                      minLength: {
                        value: 10,
                        message: "Number should be at least 10 characters",
                      },
                    }}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        className={classNames("", {
                          "is-invalid": errors?.contact_no,
                          "is-valid": getValues("contact_no"),
                        })}
                        onChange={(value) => field.onChange(value)}
                        type="text"
                        placeholder="Enter Mobile Number"
                        onKeyDown={(event) => {
                          if (!RegxExpression.phone.test(event.key)) {
                            if (event.key !== "Backspace") {
                              event.preventDefault();
                            }
                          }
                        }}
                        maxLength={10}
                      />
                    )}
                  />
                  {errors?.contact_no && (
                    <sup className="text-danger">
                      {errors?.contact_no?.message}
                    </sup>
                  )}
                </div>

                <div className="form-group">
                  <Form.Label>Enter Project Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    type="text"
                    name="name"
                    placeholder="Enter Description"
                    {...register("description", {
                      // required: "Description required",
                    })}
                  />
                </div>
              </div>

              <div className="form-bg mt-3">
                <Accordion defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Add Ons<span> (Optional)</span>{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="acc-opn mb-3">
                        <div className="add-one">
                          <h6 className="addh6">ADD-ONS</h6>
                          <Controller
                            name="add_on_id"
                            control={control}
                            defaultValue={[]}
                            render={({ field }) => (
                              <>
                                {data?.studio_add_ons?.map((val) => (
                                  <Form.Check
                                    key={val.id}
                                    type="checkbox"
                                    label={`${val.add_on.name} + ${val.rate}`}
                                    onChange={(e) => {
                                      setValue("calculation_status", false);
                                      setChecked(e.target.checked);
                                      setIsCalculationDone(false);
                                      handleCheckboxChange(
                                        val.id,
                                        e.target.checked,
                                        val.rate
                                      );
                                    }}
                                    checked={field.value.includes(val.id)}
                                  />
                                ))}
                              </>
                            )}
                          />
                        </div>
                      </div>
                      <div className="acc-opn">
                        <div className="add-one">
                          <h6 className="addh6">ADDITIONAL SUPPORT</h6>
                          <Form>
                            <Controller
                              name="additional_support_id"
                              control={control}
                              defaultValue={[]}
                              render={({ field }) => (
                                <>
                                  {dataSupport?.map((val) => (
                                    <Form.Check
                                      key={val.id}
                                      type="checkbox"
                                      label={`${val.name}`}
                                      onChange={(e) =>
                                        handleCheckboxChangeSupport(
                                          val.id,
                                          e.target.checked
                                        )
                                      }
                                      checked={field.value.includes(val.id)}
                                    />
                                  ))}
                                </>
                              )}
                            />
                            <div className="form-group mt-5">
                              <Form.Label className="addlabhh">
                                Add Additional Support
                              </Form.Label>
                              <Form.Control
                                as="textarea"
                                type="text"
                                name="name"
                                placeholder="Enter Description"
                                {...register("support_description", {})}
                              />
                            </div>
                            {
                              <div className="row justify-content-end">
                                <div className="col-md-6 col-12">
                                  <div className="Bifurcation_charges_text">
                                    <div className="Bifurcation_content-holder">
                                      <div className="text-holder">
                                        <h4>Days:-</h4>
                                        <span> {details?.days}</span>
                                      </div>
                                      <div className="text-holder">
                                        <h4>
                                          Full Day Charges x (
                                          {details?.charges?.full_h_c}) :-
                                        </h4>
                                        <span>
                                          ₹ {details?.charges?.full_h_p}
                                        </span>
                                      </div>
                                      <div className="text-holder">
                                        <h4>
                                          Half Day Charges x (
                                          {details?.charges?.half_h_c}) :-
                                        </h4>
                                        <span>
                                          ₹ {details?.charges?.half_h_p}
                                        </span>
                                      </div>
                                      <div className="text-holder">
                                        <h4>
                                          Hourly Basis Charges x (
                                          {details?.charges?.min_h_c}) :-
                                        </h4>
                                        <span>
                                          ₹ {details?.charges?.min_h_p}
                                        </span>
                                      </div>

                                      <div className="text-holder">
                                        <h4>Add On Charges:-</h4>
                                        <span>₹ {details?.add_one_price}</span>
                                      </div>

                                      <hr></hr>

                                      <div className="text-holder">
                                        <h4>Base Total:-</h4>
                                        <span>₹ {details?.base_price}</span>
                                      </div>

                                      {/* <div className="text-holder">
                                        <h4>Base Price Discount:-</h4>
                                        <span>
                                          ₹ {details?.base_price_discount}%
                                        </span>
                                      </div> */}

                                      <div className="text-holder">
                                        <h4>
                                          Base Price Discount(
                                          {details?.base_price_discount}%) :-
                                        </h4>
                                        <span>
                                          - ₹ {details?.basepricediscountrupees}
                                        </span>
                                      </div>

                                      <div className="text-holder">
                                        <h4>
                                          Free Hours(
                                          {details?.free_h_p_discount != 0 &&
                                            userSubscription?.subscription
                                              ?.subscription_detail
                                              ?.free_booking_hours}
                                          ):-
                                        </h4>
                                        <span>
                                          - ₹ {details?.free_h_p_discount}
                                        </span>
                                      </div>

                                      <div className="text-holder">
                                        <h4>After Offer :-</h4>
                                        <span> ₹ {details?.afteroffer}</span>
                                      </div>

                                      <div className="text-holder">
                                        <h4>
                                          {" "}
                                          PlatFrom Charges(
                                          {details?.platform_charge}%)
                                        </h4>
                                        <span>+ ₹ {details?.p_charge_p}</span>
                                      </div>

                                      <div className="text-holder">
                                        <h4> GST({details?.gst}%) :-</h4>
                                        <span>
                                          + ₹ {details?.gstchargestoshow}
                                        </span>
                                      </div>

                                      <div className="text-holder">
                                        <h4>Total:-</h4>
                                        <span>₹ {details?.total}</span>
                                      </div>
                                    </div>

                                    <div className="calculate-btn-holder">
                                      {!isCalculationDone && ( // Conditionally render the button
                                        <button
                                          type="button"
                                          className="calculate-btn"
                                          disabled={isButtonDisabled}
                                          onClick={() => {
                                            clearErrors("calculation_status");
                                            handleSubmit(onSubmitCalculation)();
                                          }}
                                        >
                                          Check Price
                                        </button>
                                      )}

                                      {errors.calculation_status && (
                                        <div className="text-danger">
                                          {errors.calculation_status.message}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            }
                          </Form>
                        </div>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>

              {userdata?.subscription_status == 1 ? (
                data?.premium_studio_status == 1 &&
                userSubscription?.premium_studio_count <= 0 ? (
                  <>
                    <div className="text-center mt-5">
                      <button
                        type="button"
                        className="proceed-btn"
                        onClick={() =>
                          alert("Access To Premium Studios has Exceeded")
                        }
                      >
                        Proceed to Pay
                      </button>
                    </div>
                  </>
                ) : data?.premium_studio_status == 1 &&
                  userSubscription?.premium_studio_count == "All" ? (
                  <div className="text-center mt-5">
                    <buttton
                      className="proceed-btn"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Proceed to Pay
                    </buttton>
                  </div>
                ) : (
                  <div className="text-center mt-5">
                    <buttton
                      className="proceed-btn"
                      onClick={handleSubmit(onSubmit)}
                    >
                      Proceed to Pay
                    </buttton>
                  </div>
                )
              ) : data?.premium_studio_status == 1 ? (
                <div className="text-center mt-5">
                  <button
                    className="proceed-btn"
                    type="button"
                    onClick={() => alert("You cannot Book Premium Studio")}
                  >
                    Proceed to Pay
                  </button>
                </div>
              ) : (
                <div className="text-center mt-5">
                  <buttton
                    type="button"
                    className="proceed-btn"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Proceed to Pay
                  </buttton>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>

      <Success_modal
        maintext={"Booking Successfully"}
        smalltext={"Soon you will be notified by email & sms."}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Daily_form;
