import React, { useEffect, useRef, useState, useContext } from "react";
import "./home-about-us.css";
import { Link } from "react-router-dom";
import { Context } from "../../../utils/context";
import { HomeAbout } from "../../../utils/apis/AboutUs/AboutUs";
import parse from "html-react-parser";
const Home_about_us = () => {
  const [data, setData] = useState();
  const { IMG_URL } = useContext(Context);
  const getAllData = async () => {
    const res = await HomeAbout();
    if (res?.success) {
      setData(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);
  return (
    <>
      <div className="container">
        <section className="Home_about_us_section">
          <div className="row">
            <div className="col-xxl-8 col-xl-7 col-lg-7 mx-auto">
              <div className="heading-text-div">
                <h1 className="about-text">About</h1>
                <p className="middle-content">
                  {typeof data?.description === "string"
                    ? parse(data?.description)
                    : ""}
                </p>
                <h1 className="us-text">Us</h1>
                <Link to="/aboutus" className="readmore">
                  READ MORE
                </Link>
              </div>
            </div>
          </div>

          <div className="overlay-images">
            <div className="leftdiv">
              <img className="leftside" src={IMG_URL + data?.image} />
            </div>

            <div className="righttdiv">
              <img className="rightside" src={IMG_URL + data?.image2} />
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Home_about_us;
