import React, { useEffect, useRef, useState, useContext } from "react";
import "./Studio_card_one.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../utils/context";
import parse from "html-react-parser";

const Studio_card_one = ({ data }) => {
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  const { IMG_URL, signin } = useContext(Context);
  const [mapSrc, setMapSrc] = useState("");

  // console.log(data.lat, "latttttttttttttttttttttttttttttttttttttttttttttt");

  useEffect(() => {
    if (data?.lat && data?.long) {
      setMapSrc(
        `https://maps.google.com/maps?width=600&height=400&hl=en&q=${data.lat},${data.long}&t=&z=14&ie=UTF8&iwloc=B&output=embed`
      );
    }
  }, [data]);

  const handleBookClick = () => {
    if (signin) {
      setModalShow(true);
    } else {
      navigate("/login"); // Redirect to the login page
    }
  };

  const ratings = data?.studio_reviews?.map((rate) => rate?.ratings);
  const totalRatings = ratings?.reduce((sum, rating) => sum + rating, 0);
  const averageRating =
    ratings && ratings.length > 0 ? totalRatings / ratings.length : 0;

  return (
    <section className="Studio_card_one">
      <div className="container">
        <div className="row card-bgg">
          <div className="col-xxl-7 col-lg-6 p-4">
            <div className="sec-1">
              <div className="text-map-holder">
                <div className="row">
                  <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 col-6 ">
                    <h1 className="stud-one">{data?.name}</h1>
                    <p className="starp">
                      {data?.average_rating ? data?.average_rating : 0}
                      <FontAwesomeIcon className="staricon" icon={faStar} />
                    </p>

                    <p className="contett">
                      Capacity Of people: {data?.studio_crew_capacity}
                    </p>
                  </div>
                  <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-6 col-6 ">
                    <div className="map-holder">
                      <div className="map-section">
                        <div className="mapouter">
                          <div className="gmap_canvas">
                            <iframe
                              className="gmap_iframe"
                              frameBorder="0"
                              scrolling="no"
                              marginHeight="0"
                              marginWidth="0"
                              src={mapSrc}
                              title="Location Map"
                            ></iframe>
                          </div>
                        </div>

                        <img
                          className="close-map-icon"
                          src={`${process.env.PUBLIC_URL}/assets/images/icons/closemap.png`}
                          alt="Close Map"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <p className="contett">
                {typeof data?.description === "string"
                  ? parse(data?.description)
                  : "No description available."}
              </p>
              {signin ? (
                <div className="d-flex">
                  <Link to={`/daily-bookings/${data?.id}`}>
                    <button className="book-btn" type="button">
                      Book
                    </button>
                  </Link>
                  <div className="my-auto">
                    <p className="startinfp">
                      Starting from: ₹ {data?.hourly_basis_charges}/Hr
                    </p>
                  </div>
                </div>
              ) : (
                <div className="d-flex">
                  <Link to={`/login`}>
                    <button className="book-btn" type="button">
                      Book
                    </button>
                  </Link>
                  <div className="my-auto">
                    <p className="startinfp">
                      Starting from: ₹ {data?.hourly_basis_charges}/Hr
                    </p>
                  </div>
                </div>
              )}
              {/* <div className="d-flex">
                <Link to={`/daily-bookings/${data?.id}`}>
                  <button className="book-btn" type="button">
                    Book
                  </button>
                </Link>
                <div className="my-auto">
                  <p className="startinfp">
                    Starting from: ₹ {data?.hourly_basis_charges}/Hr
                  </p>
                </div>
              </div> */}

              {/* Uncomment this modal if needed */}
              {/* <Book_studio_modal
                name={data?.name}
                studio_id={data?.id}
                image={data?.studio_images[0]?.images}
                show={modalShow}
                onHide={() => setModalShow(false)}
              /> */}
            </div>
          </div>

          <div className="col-xxl-5 col-lg-6 p-4">
            <img
              className="stud-img"
              src={IMG_URL + data?.studio_images[0]?.images}
              alt={data?.name}
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Studio_card_one;
