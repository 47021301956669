import React, { useContext, useEffect, useState } from "react";
import "./add-review-modal.css";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ReactStars from "react-rating-stars-component";
import Success_modal from "../Success_modal/Success_modal";
import { faStar as solidStar } from "@fortawesome/free-solid-svg-icons";
import { faStar as regularStar } from "@fortawesome/free-regular-svg-icons";
import { Context } from "../../../../utils/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, Controller, SubmitHandler } from "react-hook-form";

import classNames from "classnames";
import { postRating } from "../../../../utils/apis/myAccount/myAccount";
const Add_review_modal = (props) => {
  const [successModalShow, setSuccessModalShow] = React.useState(false);
  const [ratings, setRatings] = useState([1, 0, 0, 0, 0]);
  const handleSubmit1 = () => {
    setSuccessModalShow(true);
    props.onHide();
  };

  const renderStars = () => {
    return ratings.map((rating, index) => (
      <FontAwesomeIcon
        key={index}
        icon={rating === 1 ? solidStar : regularStar}
        className="star-icon me-1"
        onClick={() => handleStarClick(index + 1)}
      />
    ));
  };

  const handleStarClick = (starIndex) => {
    const newRatings = [...ratings];
    var rate = 1;
    for (let i = 0; i < newRatings.length; i++) {
      newRatings[i] = i < starIndex ? 1 : 0;
    }
    newRatings?.map((value, index) => {
      if (value == 1) {
        rate = index + 1;
      }
    });

    setRatings(newRatings);
    setValue("ratings", rate);
  };

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm({
    defaultValues: {
      ratings: 1,
    },
  });

  const [error, setErrors] = useState();
  console.log(error, "error");

  const { signin } = useContext(Context);
  const onSubmit = async (data) => {
    const sendData = new FormData();
    sendData.append("ratings", data?.ratings);
    sendData.append("review_message", data?.review_message);
    sendData.append("studio_id", props?.studioId);

    const res = await postRating(sendData);
    if (res?.success) {
      setSuccessModalShow(true);
      props.onHide();
      props.getAllBookingDataHistory();
      //   getProductReview();
      //   getUserReview();
      //   setModalShow(true);
      //   setTimeout(() => {
      //     setModalShow(false);
      //   }, 3000);
    } else {
      setErrors(res);
      // setErrors(res);
    }
  };
  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        className="Add_review_modal"
        centered
      >
        <Modal.Body>
          <div className="d-flex ratingflex ">
            <div className="my-auto">
              <p>Rate Studio One:</p>
            </div>
            <div className="">{renderStars()}</div>
          </div>
          <h4>Add Review</h4>
          <Form>
            <Form.Control
              as="textarea"
              name="review_message"
              type="text"
              rows={3}
              placeholder="Add your review..."
              {...register("review_message", {
                required: "Message required",
              })}
              className={classNames("", {
                "is-invalid": errors?.review_message,
              })}
            />

            {/* Show error message */}
            {errors.review_message && (
              <div className="text-danger mt-1">
                {errors.review_message.message}
              </div>
            )}
          </Form>{" "}
        </Modal.Body>

        <Modal.Footer>
          <Button className="cancelbtn" onClick={props.onHide}>
            Cancel
          </Button>
          <Button className="submitbtn" onClick={handleSubmit(onSubmit)}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Success_modal
        maintext={"Review Added Successfully"}
        smalltext={""}
        show={successModalShow}
        onHide={() => setSuccessModalShow(false)}
      />
    </>
  );
};

export default Add_review_modal;
